import React, { Component } from 'react'
import axios from 'axios'

export default class UserCreate extends Component {

    state = {
        users: [],
        nombre: '',
        apellido: '',
        correo: '',
        telefono: '',
        username: '',
        password: ''
    }

    onChangeNombre = (n) => {
        this.setState({
            nombre: n.target.value
        })
    }

    onChangeApellido = (a) => {
        this.setState({
            apellido: a.target.value
        })
    }

    onChangeCorreo = (c) => {
        this.setState({
            correo: c.target.value
        })
    }

    onChangeTelefono = (t) => {
        this.setState({
            telefono: t.target.value
        })
    }

    onChangeUsername = (u) => {
        this.setState({
            username: u.target.value
        })
    }

    onChangePassword = (p) => {
        this.setState({
            password: p.target.value
        })
    }

    onSubmit = async () => {
        await axios.post('https://viajesmaxitours.herokuapp.com/api/users', {
            nombre: this.state.nombre,
            apellido: this.state.apellido,
            correo: this.state.correo,
            telefono: this.state.telefono,
            username: this.state.username,
            password: this.state.password
        });
    }

    render() {
        return (
            <div className='row'>
                <div>
                    <ul className="list-group">
                        <div className='card card-body'>
                            <h3>Crear Nuevo Usuario</h3>
                            <form onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <input type="text" className='form-control' onChange={this.onChangeNombre} placeholder='Nombre' />
                                    <input type="text" className='form-control' onChange={this.onChangeApellido} placeholder='Apellido' />
                                    <input type="text" className='form-control' onChange={this.onChangeCorreo} placeholder='Correo' />
                                    <input type="text" className='form-control' onChange={this.onChangeTelefono} placeholder='Teléfono' />
                                    <input type="text" className='form-control' onChange={this.onChangeUsername} placeholder='Usuario' />
                                    <input type="text" className='form-control' onChange={this.onChangePassword} placeholder='Contraseña' />
                                </div>
                                <button type='submit'>Crear Usuario</button>
                            </form>
                        </div>
                    </ul>
                </div>
            </div>
        )
    }
}
