import React from 'react';
//import { BrowserRouter,Routes,Route } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Navigation from './Components/navigation'
import Users from './Components/Users/Users'
import UserEdit from './Components/Users/UsersEdit'
import Tours from './Components/Tours/Tours'
import ToursEdit from './Components/Tours/ToursEdit'
import Home from './Components/Home'

const App = () => {
  return (
    <div>
      <Navigation />
      <Routes>

        <Route exact path="/usuarios" element={<Users />} />
        <Route exact path='/usuarios/editar/:id' element={<UserEdit/>}/>
        <Route exact path="/tours" element={<Tours />} />
        <Route exact path='/tours/editar/:id' element={<ToursEdit/>}/>
        <Route exact path="/" element={<Home />} />

      </Routes>
    </div>
  );
}

export default App;
