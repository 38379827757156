import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'



export default class UserEdit extends Component {


    state = {
        users: [],
        nombre: '',
        apellido: '',
        correo: '',
        telefono: '',
        username: '',
        password: ''
    }



    onChangeNombre = (n) => {
        this.setState({
            nombre: n.target.value
        })
    }

    onChangeApellido = (a) => {
        this.setState({
            apellido: a.target.value
        })
    }

    onChangeCorreo = (c) => {
        this.setState({
            correo: c.target.value
        })
    }

    onChangeTelefono = (t) => {
        this.setState({
            telefono: t.target.value
        })
    }

    onChangeUsername = (un) => {
        this.setState({
            username: un.target.value
        })
    }

    onChangePassword = (p) => {
        this.setState({
            password: p.target.value
        })
    }

    getUser = async (id) => {
        const res = await axios.get('https://viajesmaxitours.herokuapp.com/api/users/' + id);
        return await res.data
        ;
    }



    async componentDidMount() {


        const path = window.location.pathname
        let lista = path.split('/');
        let id = lista[lista.length - 1]

        
        let user = this.getUser(id)
        user.then(response=>(this.setState({
                nombre: response.nombre,
                apellido: response.apellido,
                correo: response.correo,
                telefono: response.telefono,
                username: response.username,
                password: response.password,
                id: id
            })))
            ;
    }

    onEdit = async () => {
        await axios.put('https://viajesmaxitours.herokuapp.com/api/users/'+ this.state.id, {
            nombre: this.state.nombre,
            apellido: this.state.apellido,
            correo: this.state.correo,
            telefono: this.state.telefono,
            username: this.state.username,
            password: this.state.password
        })
    }



    render() {
        return (
            <div className='row'>
                <div>
                    <ul className="list-group">
                        <div className='card card-body'>
                            <h3>Editar Usuario Existente</h3>
                            <form onSubmit={this.onEdit}>
                                <div className="form-group">
                                    <input type="text" className='form-control' onChange={this.onChangeNombre} placeholder='Nombre' value={this.state.nombre} />
                                    <input type="text" className='form-control' onChange={this.onChangeApellido} placeholder='Apellido' value={this.state.apellido} />
                                    <input type="text" className='form-control' onChange={this.onChangeCorreo} placeholder='Correo' value={this.state.correo} />
                                    <input type="text" className='form-control' onChange={this.onChangeTelefono} placeholder='Teléfono' value={this.state.telefono} />
                                    <input type="text" className='form-control' onChange={this.onChangeUsername} placeholder='Usuario' value={this.state.username} />
                                    <input type="text" className='form-control' onChange={this.onChangePassword} placeholder='Contraseña' value={this.state.password} />
                                </div>
                                <Link className='btn btn-dark' onClick={this.onEdit()} to={'/usuarios'}>Editar</Link>
                                {/*<button type='submit'>Editar Usuario</button>*/}
                            </form>
                        </div>
                    </ul>
                </div>
            </div>
        )
    }
}
