import React, { Component } from 'react'
import axios from 'axios'

export default class ToursCreate extends Component {

    state = {
        tours: [],
        tourName: '',
        limit: '',
        precioAdulto: '',
        precioNino: ''
    }

    onChangeTourName = (n) => {
        this.setState({
            tourName: n.target.value
        })
    }

    onChangeLimit = (l) => {
        this.setState({
            limit: l.target.value
        })
    }

    onChangePrecioAdulto = (pA) => {
        this.setState({
            precioAdulto: pA.target.value
        })
    }

    onChangePrecioNino = (pN) => {
        this.setState({
            precioNino: pN.target.value
        })
    }

    onSubmit = async () => {
        await axios.post('https://viajesmaxitours.herokuapp.com/api/tours', {

            tourName: this.state.tourName,
            limit: this.state.limit,
            precioAdulto: this.state.precioAdulto,
            precioNino: this.state.precioNino
            })
    };

    render() {
        return (
            <div className='row'>
                <div>
                    <ul className="list-group">
                        <div className='card card-body'>
                            <h3>Crear Nuevo Tour</h3>
                            <form onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <input type="text" className='form-control' onChange={this.onChangeTourName} placeholder='Nombre del Tour' />
                                    <input type="text" className='form-control' onChange={this.onChangeLimit} placeholder='Limite' />
                                    <input type="text" className='form-control' onChange={this.onChangePrecioAdulto} placeholder='Precio Adulto' />
                                    <input type="text" className='form-control' onChange={this.onChangePrecioNino} placeholder='Precio Niño' />                                    
                                </div>
                                <button type='submit'>Crear Tour</button>
                            </form>
                        </div>
                    </ul>
                </div>
            </div>
        )
    }
}
