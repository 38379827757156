import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'



export default class ToursEdit extends Component {

    state = {
        tours: [],
        tourName: '',
        limit: '',
        precioAdulto: '',
        precioNino: ''
    }



    onChangeTourName = (t) => {
        this.setState({
            tourName: t.target.value
        })
    }

    onChangeLimit = (l) => {
        this.setState({
            limit: l.target.value
        })
    }

    onChangePrecioAdulto = (pA) => {
        this.setState({
            precioAdulto: pA.target.value
        })
    }

    onChangePrecioNino = (pN) => {
        this.setState({
            precioNino: pN.target.value
        })
    }


    getTour = async (id) => {
        const res = await axios.get('https://viajesmaxitours.herokuapp.com/api/tours/' + id);
        return await res.data
        ;
    }



    async componentDidMount() {


        const path = window.location.pathname
        let lista = path.split('/');
        let id = lista[lista.length - 1]

        
        let tour = this.getTour(id)
        tour.then(response=>(this.setState({
                tourName: response.tourName,
                limit: response.limit,
                precioAdulto: response.precioAdulto,
                precioNino: response.precioNino,         
                id: id
            })))
            ;
    }

    onEdit = async () => {
        await axios.put('https://viajesmaxitours.herokuapp.com/api/tours/'+ this.state.id, {
            tourName: this.state.tourName,
            limit: this.state.limit,
            precioAdulto: this.state.precioAdulto,
            precioNino: this.state.precioNino
        })
}



    render() {
        return (
            <div className='row'>
                <div>
                    <ul className="list-group">
                        <div className='card card-body'>
                            <h3>Editar Tour Existente</h3>
                            <form onSubmit={this.onEdit}>
                                <div className="form-group">
                                    <input type="text" className='form-control' onChange={this.onChangeTourName} placeholder='Nombre del Tour' value={this.state.tourName} />
                                    <input type="text" className='form-control' onChange={this.onChangeLimit} placeholder='Limite' value={this.state.limit} />
                                    <input type="text" className='form-control' onChange={this.onChangePrecioAdulto} placeholder='Precio Adulto' value={this.state.precioAdulto} />
                                    <input type="text" className='form-control' onChange={this.onChangePrecioNino} placeholder='Precio Niño' value={this.state.precioNino} />
                                </div>
                                <Link className='btn btn-dark' onClick={this.onEdit()} to={'/tours'}>Editar</Link>
                            </form>
                        </div>
                    </ul>
                </div>
            </div>
        )
    }
}
