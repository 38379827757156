import React, { Component } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';

export default class ToursGet extends Component {



    state = {
        tours: []
    }

    getTours = async ()=>{ const res = await axios.get('https://viajesmaxitours.herokuapp.com/api/tours');
        this.setState({ tours: res.data });
    }
    async componentDidMount() {
        this.getTours();        
    }
    deleteTour = async (id) =>{
        await axios.delete('https://viajesmaxitours.herokuapp.com/api/tours/' + id)
        this.getTours();
    }
    render() {
        return (
            <div className='row'>
                <div className='col-md-11'>
                    <ul className="list-group">
                        <h3>Toures Registrados</h3>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Tour</th>
                                        <th scope="col">Limite</th>
                                        <th scope="col">Precio Adulto</th>
                                        <th scope="col">Precio Niño</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tours.map((t) => (
                                        <tr>
                                            <th scope="row">{t._id}</th>
                                            <td>{t.tourName}</td>
                                            <td>{t.limit}</td>
                                            <td>{t.precioAdulto}</td>
                                            <td>{t.precioNino}</td>
                                            <td><button type="submit" onClick={()=>this.deleteTour(t._id)}>Eliminar</button></td>
                                            <Link className='btn btn-secondary' to={'editar/'+ t._id}> Editar </Link> 
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                    </ul>
                </div>
            </div>
        )
    }
} 