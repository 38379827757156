import { Component } from "react";
import axios from 'axios';
import {Link} from 'react-router-dom';

export default class UserGet extends Component {

    state = {
        users: []
    }
    getUsers = async () => {
        const res = await axios.get('https://viajesmaxitours.herokuapp.com/api/users');
        this.setState({ users: res.data });
    }
    async componentDidMount() {
        this.getUsers();
    }

    deleteUser = async (id) =>{
        await axios.delete('https://viajesmaxitours.herokuapp.com/api/users/' + id)
        this.getUsers();
    }

    render() {
        return (
            <div className='row'>
                <div>
                    <ul className="list-group col md 11 p-4" >
                        <div>
                            <h3>Usuarios Registrados</h3>
                            <table className="table table-striped">                                
                                <thead>
                                    <tr>
                                        {/* <th scope="col">ID</th> */}
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Apellido</th>
                                        <th scope="col">Correo</th>
                                        <th scope="col">Teléfono</th>
                                        <th scope="col">Usuario</th>
                                        <th scope="col">Contraseña</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.users.map((u) => (
                                        <tr>
                                            {/* <td>{u._id}</td> */}
                                            <td>{u.nombre}</td>
                                            <td>{u.apellido}</td>
                                            <td>{u.correo}</td>
                                            <td>{u.telefono}</td>
                                            <td>{u.username}</td>
                                            <td>{u.password}</td>
                                            <td><button type="submit" onClick={()=>this.deleteUser(u._id)}>Eliminar</button></td>
                                            <Link className='btn btn-secondary' to={'editar/'+ u._id}> Editar </Link>                                           
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </ul>
                </div>
            </div>
        )
    }
} 
