import React, { Component } from 'react'
import ToursCreate from './ToursCreate'
import ToursGet from './ToursGet'



export default class Users extends Component {

    render() {
        return (
            <div className='row'>
                <div style={{ width: '100%', height: '100%' }}>
                    <ToursCreate/>
                </div>
                <div style={{ width: '100%', height: '100%' }}>
                    <ToursGet />
                </div>
            </div>
        )
    }
} 