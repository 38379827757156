import React, { Component } from 'react'
import UserCreate from './UsersCreate';
import UserGet from './UsersGet';


export default class Users extends Component {

    render() {
        return (
            <div className='row'>
                <div style={{ width: '100%', height: '100%' }}>
                    <UserCreate />
                </div>
                <div style={{ width: '100%', height: '100%' }}>
                    <UserGet />
                </div>
            </div>
        )
    }
} 